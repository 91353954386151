<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb"></div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 330px"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="接收人类型" prop="sex">
            <el-select
              v-model="formInline.dispatchType"
              @change="dispatchChange(formInline.dispatchType)"
            >
              <el-option
                v-for="value in dispatchList"
                :label="value.desc"
                :value="value.code"
                :key="value.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接收人" v-if="formInline.dispatchType === 10">全平台</el-form-item>
          <el-form-item
            label="接收人"
            v-if="formInline.dispatchType === 11 || formInline.dispatchType === 12"
            style="width: 700px"
            prop="parkIds"
          >
            <el-transfer
              ref="transfer"
              filterable
              :filter-method="filterStationMethod"
              :titles="['未选择', '已选择']"
              filter-placeholder="请输入名称"
              v-model="selectPark"
              :data="parkList"
            >
            </el-transfer>
          </el-form-item>
          <el-form-item label="发送内容：" prop="remark">
            <el-input
              rows="4"
              resize="none"
              type="textarea"
              style="width: 350px"
              v-model="formInline.content"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
          <el-button type="primary" @click="submitData" style="width: 88px">发送</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import //  dateFormat
"@/common/js/public.js";
export default {
  name: "hello",
  data() {
    return {
      parkList: [], // 未选择车场
      selectPark: [], // 选中的车场
      dispatchList: [],
      loading: false,
      detailFlag: false,
      filterStationMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      formInline: {
        dispatchType: "",
        content: "",
      },
      rules: {
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    dispatchChange(code) {
      // console.log(code, 'dsa')
      if (code === 11) {
        this.getParkInfo1();
      } else if (code === 12) {
        this.getParkInfo();
      }
    },
    // 接收人类型
    getDispatchList() {
      this.$axios.get("/acb/2.0/dispatch/getDispatchType").then((res) => {
        if (res.state == 0) {
          this.dispatchList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 选择未分配车场
    getParkInfo(pdaManagerName) {
      this.$axios
        .get("/acb/2.0/pdaManager/getAllPdaManager/", {
          data: {
            pdaManagerName: pdaManagerName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkList = [];
            let data = [];
            res.value.forEach((item, index) => {
              data.push({
                label: item.pdaManagerName + "(" + item.account + ")",
                key: item.pdaManagerId,
                parkId: item.pdaManagerId,
              });
              this.parkList = data;
              // console.log(data, 'ddd')
              // console.log(this.parkList, 'this.parkList')
              //          // console.log(this.parkList, 'parkList')
            });
            return data;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getParkInfo1(operationName) {
      this.$axios
        .get("/acb/2.0/parkRecord/operation/list/", {
          data: {
            operationName: operationName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkList = [];
            let data = [];
            res.value.forEach((item, index) => {
              data.push({
                label: item.operationName,
                key: item.operationId,
                parkId: item.operationId,
              });
              this.parkList = data;
              //          // console.log(this.parkList, 'parkList')
            });
            return data;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    submitData() {
      //        this.formInline.parkIds = this.selected.join(',');
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formInline.dispatchType != 10) {
            if (this.selectPark.length == 0) {
              this.$alert("请绑定接收人", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              return;
            }
          }
          if (!this.formInline.dispatchType) {
            this.$alert("接收人类型不能为空", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (!this.formInline.content) {
            this.$alert("发送内容不能为空", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.$axios
            .post("/acb/2.0/dispatch/create/", {
              data: {
                dispatchType: this.formInline.dispatchType,
                content: this.formInline.content,
                ids: this.selectPark.join(","),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  components: {},
  created() {},
  mounted() {
    this.getDispatchList();
  },
  computed: {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
</style>
<style scoped>
.chosePark {
  float: right;
  margin-right: -70px;
  margin-top: -52px;
}
</style>
