var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "breadcrumb" }),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "330px" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接收人类型", prop: "sex" } },
                [
                  _c(
                    "el-select",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.dispatchChange(_vm.formInline.dispatchType)
                        },
                      },
                      model: {
                        value: _vm.formInline.dispatchType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "dispatchType", $$v)
                        },
                        expression: "formInline.dispatchType",
                      },
                    },
                    _vm._l(_vm.dispatchList, function (value) {
                      return _c("el-option", {
                        key: value.code,
                        attrs: { label: value.desc, value: value.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.formInline.dispatchType === 10
                ? _c("el-form-item", { attrs: { label: "接收人" } }, [
                    _vm._v("全平台"),
                  ])
                : _vm._e(),
              _vm.formInline.dispatchType === 11 ||
              _vm.formInline.dispatchType === 12
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "700px" },
                      attrs: { label: "接收人", prop: "parkIds" },
                    },
                    [
                      _c("el-transfer", {
                        ref: "transfer",
                        attrs: {
                          filterable: "",
                          "filter-method": _vm.filterStationMethod,
                          titles: ["未选择", "已选择"],
                          "filter-placeholder": "请输入名称",
                          data: _vm.parkList,
                        },
                        model: {
                          value: _vm.selectPark,
                          callback: function ($$v) {
                            _vm.selectPark = $$v
                          },
                          expression: "selectPark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发送内容：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      rows: "4",
                      resize: "none",
                      type: "textarea",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.formInline.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "content", $$v)
                      },
                      expression: "formInline.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "left",
                "margin-left": "8.5%",
                "margin-top": "40px",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("发送")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }